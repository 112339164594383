import React, { useEffect, useState } from "react";
import styles from "./styles/App.module.sass";
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import { ROUTES } from "./constants/ROUTES";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import DismantlingPage from "./pages/DismantlingPage";
import ReviewsPage from "./pages/ReviewsPage";
import VacancyPage from "./pages/VacancyPage";
import ContactsPage from "./pages/ContactsPage";
import PricePage from "./pages/PricePage";
import AppContext from "./contexts/AppContext";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Licenses from "./pages/Licenses";

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCalculationPopupOpen, setIsCalculationPopupOpen] = useState(false);
  const [isSpecialOfferPopup, setIsSpecialOfferPopup] = useState(false);
  const [isFixedPopupOpen, setIsFixedPopupOpen] = useState(false);
  const [isPopupWithMessage, setIsPopupWithMessage] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [calculatedData, setCalculatedData] = useState(0);
  const [metalGroup, setMetalGroup] = useState("чёрный металл");

  const appValues = {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    isCalculationPopupOpen,
    setIsCalculationPopupOpen,
    isSpecialOfferPopup,
    setIsSpecialOfferPopup,
    isFixedPopupOpen,
    setIsFixedPopupOpen,
    isPopupWithMessage,
    setIsPopupWithMessage,
    isToastOpen,
    setIsToastOpen,
    isBurgerActive,
    setIsBurgerActive,
    metalGroup,
    setMetalGroup,
    calculatedData,
    setCalculatedData,
  };

  // disable body scroll when burger menu open
  useEffect(() => {
    const menu = document.querySelector("#burgerMenu");

    if (isBurgerActive || isMobileMenuOpen) return disableBodyScroll(menu);
    return enableBodyScroll(menu);
  }, [isBurgerActive, isMobileMenuOpen]);

  return (
    <div className={styles.app}>
      <AppContext.Provider value={appValues}>
        <Routes>
          <Route path={ROUTES.MAIN} element={<MainPage />} />
          <Route path={ROUTES.ABOUT} element={<AboutPage />} />
          <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
          <Route path={ROUTES.DISMANTLING} element={<DismantlingPage />} />
          <Route path={ROUTES.REVIEWS} element={<ReviewsPage />} />
          <Route path={ROUTES.VACANCY} element={<VacancyPage />} />
          <Route path={ROUTES.PRICE} element={<PricePage />} />
          <Route path={ROUTES.CONTACTS} element={<ContactsPage />} />
          <Route path={ROUTES.LICENSES} element={<Licenses />} />
        </Routes>
      </AppContext.Provider>
    </div>
  );
}

export default App;
