import React from "react";
import BecomeOurClient from "../components/Sections/BecomeOurClient";
import GuaranteesSection from "../components/Sections/GuaranteesSection";
import HomeSection from "../components/Sections/HomeSection";
import PartnersSection from "../components/Sections/PartnersSection";
import ReviewsSection from "../components/Sections/ReviewsSection";
import TablePriceSection from "../components/Sections/TablePriceSection";
import WhyWeFirstSection from "../components/Sections/WhyWeFirstSection";
import MainLayout from "../layouts/MainLayout";

function PricePage() {
  return (
    <MainLayout>
      <HomeSection />
      <TablePriceSection />
      {/* <Calculator /> */}
      <WhyWeFirstSection />
      <GuaranteesSection />
      <BecomeOurClient />
      <PartnersSection />
      <ReviewsSection />
    </MainLayout>
  );
}

export default PricePage;
