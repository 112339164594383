import React from "react";
import SectionTitle from "./SectionTitle";
import AboutUsSection from "./AboutUsSection";
import styles from "../../styles/sections/AboutUsSection.module.sass";
import { motion } from "framer-motion";
import truckImg from "../../images/truck.png";
import girlImg from "../../images/demontaj.png";
import chermetImg from "../../images/chermet.png";
import tsvetMet from "../../images/tsvetmet-2.png";

function AboutTextContent() {
  return (
    <>
      <AboutUsSection>
        <SectionTitle title={"Вывоз металлолома"} divider />

        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <motion.p
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className={`${styles.text} ${styles.text_wide}`}
            >
              В автопарке нашей компании есть большое количество транспорта, предназначенного для вывоза металлолома. Вы
              можете оставить заявку по телефону на сайте и быть уверенными, что мы в кратчайшие сроки вывезем ваш лом.
            </motion.p>

            {/* <motion.p
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className={`${styles.text} ${styles.text_wide}`}
            >
              В автопарке нашей компании есть огромное количество транспорта, предназначенного для вывоза металлолома и
              других материалов. В штате сотрудников работает большое количество грузчиков и стропальщиков, которые
              могут аккуратно погрузить и вывезти любое количество металла. Во время проведения строительных или
              ремонтных работ, часто скапливается большое количество металлолома или отработанных элементов, которые не
              представляют уже никакой ценности для владельца. В некоторых вида производства таких предметов
              скапливается огромное количество, которое необходимо аккуратно и быстро вывозить, чтобы не создавать
              лишних помех и нарушений техники пожарной безопасности.
            </motion.p> */}
          </div>

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.img}
            src={truckImg}
            alt="Truck"
          />
        </div>
      </AboutUsSection>

      <AboutUsSection>
        <SectionTitle title={"Демонтаж металлоконструкций"} divider />

        <div className={styles.wrapper}>
          <motion.p
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.text}
          >
            Огромное количество строительного мусора, который скапливается на площадках, где возводятся сооружения или
            здания, не только мешает эффективной и быстрой работе, но и может представлять опасность для здоровья и даже
            жизни строителей. Мы выполняем демонтаж металлоконструкций быстро, качественно и безопасно.
          </motion.p>

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.img}
            src={girlImg}
            alt="Girl"
          />
        </div>
      </AboutUsSection>

      <AboutUsSection>
        <SectionTitle title={"Прием лома черных металлов"} divider />

        <div className={styles.wrapper}>
          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.img}
            src={chermetImg}
            alt="Girl"
          />

          <motion.p
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.text}
          >
            Наши расценки на лом черных металлов, являются максимально выгодными для большинства новых и постоянных
            клиентов.
          </motion.p>
        </div>
      </AboutUsSection>

      <AboutUsSection>
        <SectionTitle title={"Прием лома цветных металлов"} divider />

        <div className={styles.wrapper}>
          <motion.p
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.text}
          >
            Разнообразие цветных металлов впечатляет, прежде чем принять цветной лом у населения или юридических лиц,
            наши специалисты точно устанавливают к какому классу относится продукция.
          </motion.p>

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={styles.img}
            src={tsvetMet}
            alt="Girl"
          />
        </div>
      </AboutUsSection>
    </>
  );
}

export default AboutTextContent;
