import React, { useState, useContext, useRef } from "react";
import styles from "../../styles/sections/ContactUsMiniSection.module.sass";
import ButtonContactUs from "../Buttons/ButtonContactUs";
import { send } from "@emailjs/browser";
import AppContext from "../../contexts/AppContext";
import ErrorToast from "../ErrorToast";

import InputMask from "react-input-mask";
import emailjs from "@emailjs/browser";

function PhoneInput(props) {
  return (
    <InputMask
      className={styles.input}
      mask="+7 (999) 999-99-99"
      name="phonenum"
      id="phone-mask"
      type="phone"
      placeholder="Номер телефона"
      value={props.value}
      onChange={props.onChange}
    ></InputMask>
  );
}

function ContactUsMiniSection() {
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const handleInput = ({ target: { value } }) => setPhone(value);
  const handleInput2 = ({ target: { value } }) => setUsername(value);
  const { setIsToastOpen } = useContext(AppContext);
  const [values, setValues] = useState({
    name: "",
    phone: "",
  });
  const [msg, setMsg] = useState("");
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);
  const onChange = (evt) => {
    const { name, value } = evt.target;
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (values.name === "" || values.phone === "") {
    } else {
      send("service_akwd9qi", "template_c8yo8yg", values, "1lCBeZyc-IMMTkpO1").then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          console.log("valuesss", values.name, values.phone);
          setValues({
            name: "",
            phone: "",
          });
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    }
  };

  const form = useRef();

  function reloadAndClear() {
    setPhone("");
    setUsername("");
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const phonenumel = form.current.phonenum.value;

    if (form.current.username.value.length > 1 && phonenumel.length === 18 && !phonenumel.includes("_")) {
      emailjs.sendForm("service_akwd9qi", "template_c8yo8yg", form.current, "15Ye39pjIHbEXm3Nz").then(
        (result) => {
          reloadAndClear();
          setIsToastOpen(true);

          setTimeout(() => {
            setIsToastOpen(false);
          }, 1500);
        },
        (error) => {
          console.log(error.text);
        }
      );
    } else {
      setMsg("Пожалуйста заполните поля корректно");
      setIsErrorToastOpen(true);
      setTimeout(() => {
        setIsErrorToastOpen(false);
      }, 1500);
    }
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            Узнайте стоимость вашего металла за <span>3 минуты !</span>
          </h2>
          <form onSubmit={sendEmail} ref={form} className={styles.form}>
            <PhoneInput value={phone} onChange={handleInput}></PhoneInput>

            <input
              name="username"
              onChange={handleInput2}
              className={styles.input}
              value={username}
              placeholder={"Ваше имя"}
              type="text"
            />
            <ButtonContactUs type="submit" values={values} />
          </form>
          <p className={styles.text}>Оставляя заявку Вы соглашайтесь с условиями на обработку песональных данных.</p>
        </div>
      </div>

      <ErrorToast msg={msg} isErrorToastOpen={isErrorToastOpen} />
    </section>
  );
}

export default ContactUsMiniSection;
