import React, { useContext, useRef, useState } from "react";
import styles from "../styles/PriceCalculationPopup.module.sass";
import AppContext from "../contexts/AppContext";
import { send } from "@emailjs/browser";
import ErrorToast from "./ErrorToast";

import InputMask from "react-input-mask";

import emailjs from "@emailjs/browser";

function PhoneInput(props) {
  return (
    <InputMask
      mask="+7 (999) 999-99-99"
      name="phonenum"
      id="phone-mask"
      type="phone"
      placeholder="Номер телефона"
      value={props.value}
      className={styles.input}
      onChange={props.onChange}
    ></InputMask>
  );
}

function PriceCalculationPopup() {
  const form = useRef();
  const { isCalculationPopupOpen, calculatedData, setIsCalculationPopupOpen, setIsToastOpen } = useContext(AppContext);

  const [values, setValues] = useState({
    name: "",
    phone: "",
  });
  const [msg, setMsg] = useState("");
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);
  const classes = {
    section: isCalculationPopupOpen ? `${styles.section} ${styles.section_active}` : styles.section,
  };

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (values.name === "" || values.phone === "") {
      setMsg("Пожалуйста заполните все поля");
      setIsErrorToastOpen(true);
      setTimeout(() => {
        setIsErrorToastOpen(false);
      }, 1500);
    } else {
      send("service_h9d8ifl", "template_s4hqftf", values, "user_B0RmS66rgUajGJ6kANxU7").then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);

          setValues({
            name: "",
            phone: "",
          });

          setIsToastOpen(true);
          setIsCalculationPopupOpen(false);

          setTimeout(() => {
            setIsToastOpen(false);
          }, 1500);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    }
  };

  const onOverlayClick = () => setIsCalculationPopupOpen(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const phonenumel = form.current.phonenum.value;

    if (form.current.username.value.length > 1 && phonenumel.length === 18 && !phonenumel.includes("_")) {
      emailjs.sendForm("service_akwd9qi", "template_c8yo8yg", form.current, "15Ye39pjIHbEXm3Nz").then(
        (result) => {
          onOverlayClick();
          setIsToastOpen(true);

          setTimeout(() => {
            setIsToastOpen(false);
          }, 1500);
        },
        (error) => {
          console.log(error.text);
        }
      );
    } else {
      setMsg("Пожалуйста заполните поля корректно");
      setIsErrorToastOpen(true);
      setTimeout(() => {
        setIsErrorToastOpen(false);
      }, 1500);
    }
  };

  const [phone, setPhone] = useState("");
  const handleInput = ({ target: { value } }) => setPhone(value);

  return (
    <section className={classes.section}>
      <div onClick={onOverlayClick} className={styles.overlay} />
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Приблизительная стоимость составляет {calculatedData} р.</h3>
        <p className={styles.text}>Для более точной информации нужно больше информации</p>
        <p className={styles.text}>Оставьте заявку и мы вам перезвоним</p>

        <form onSubmit={sendEmail} ref={form} className={styles.form}>
          <input type="text" name="username" placeholder={"Имя"} />
          <PhoneInput value={phone} onChange={handleInput}></PhoneInput>
          <button type="submit">Отправить</button>
        </form>
      </div>
      <ErrorToast msg={msg} isErrorToastOpen={isErrorToastOpen} />
    </section>
  );
}

export default PriceCalculationPopup;
