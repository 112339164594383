import React from "react";
import styles from "../../styles/sections/DismantlingServices.module.sass";
import { motion } from "framer-motion";

function DismantlingServices() {
  const showLeft = {
    hidden: {
      opacity: 0,
      x: -50,
    },

    show: {
      opacity: 1,
      x: 0,
    },
  };

  const showRight = {
    hidden: {
      opacity: 0,
      x: 50,
    },

    show: {
      opacity: 1,
      x: 0,
    },
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <motion.h3
          variants={showLeft}
          initial="hidden"
          whileInView="show"
          transition={{ duration: 0.4 }}
          className={styles.title}
        >
          Мы выполняем:
        </motion.h3>

        <motion.ul
          variants={showLeft}
          initial="hidden"
          whileInView="show"
          transition={{ duration: 0.4 }}
          className={styles.list}
        >
          <li>Демонтаж металлоконструкций;</li>
          <li>Вывоз лома цветного и черного металлов, входящих в конструкцию.</li>
        </motion.ul>

        <motion.h3
          variants={showRight}
          initial="hidden"
          whileInView="show"
          transition={{ duration: 0.4 }}
          className={styles.title}
        >
          С нами услуги демонтажа – это удобно, безопасно, надежно и с гарантией! <br /> <br /> Для Вашего комфорта мы:
        </motion.h3>

        <motion.ul
          variants={showRight}
          initial="hidden"
          whileInView="show"
          transition={{ duration: 0.4 }}
          className={styles.list}
        >
          <li>Работаем с физлицами и организациями;</li>
          <li>Районы возможного выезда – вся Москва и МО;</li>
          <li>Своя бригада опытных специалистов (водителей, грузчиков, резчиков по металлу);</li>
          <li>Собственный автопарк;</li>
          <li>Простая схема работы;</li>
          <li>Оплата за лом осуществляется по безналичному расчету или на карту.</li>
        </motion.ul>

        <motion.h3
          variants={showRight}
          initial="hidden"
          whileInView="show"
          transition={{ duration: 0.4 }}
          className={styles.title}
        >
          Легко разберем и вывезем:
        </motion.h3>

        <motion.ul
          variants={showRight}
          initial="hidden"
          whileInView="show"
          transition={{ duration: 0.4 }}
          className={styles.list}
        >
          <li>Мостовые сооружения, трубы и резервуары;</li>
          <li>Металлические части остановок общественного транспорта, торговых павильонов и производственных цехов;</li>
          <li>Отслужившее свое списанное оборудование.</li>
        </motion.ul>

        <motion.p variants={showRight} initial="hidden" whileInView="show" transition={{ duration: 0.4 }}>
          Просто свяжитесь с нами любым удобным способом: через формы и чат на сайте, по телефону или электронной почте.
        </motion.p>

        <br />

        <motion.p variants={showRight} initial="hidden" whileInView="show" transition={{ duration: 0.4 }}>
          Мы поможем Вам заработать на том, что создает неудобство на Вашей территории.
        </motion.p>
      </div>
    </section>
  );
}

export default DismantlingServices;
