import React from "react";
import styles from "../../styles/HeaderNavigation.module.sass";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../constants/ROUTES";

function HeaderNavigation() {
  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.ABOUT}>
            О компании
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.SERVICES}>
            Услуги
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.DISMANTLING}>
            Демонтаж
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.PRICE}>
            Прайс-лист
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.LICENSES}>
            Лицензии
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.REVIEWS}>
            Отзывы
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.VACANCY}>
            Вакансии
          </NavLink>
        </li>
        <li className={styles.listItem}>
          <NavLink className={styles.navLink} to={ROUTES.CONTACTS}>
            Контакты
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default HeaderNavigation;
