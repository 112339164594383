import React from "react";
import AboutTextContent from "../components/Sections/AboutTextContent";
import BecomeOurClient from "../components/Sections/BecomeOurClient";
import ContactUsSection from "../components/Sections/ContactUsSection";
import GuaranteesSection from "../components/Sections/GuaranteesSection";
import HomeSection from "../components/Sections/HomeSection";
import PartnersSection from "../components/Sections/PartnersSection";
import ReviewsSection from "../components/Sections/ReviewsSection";
import RoadmapSection from "../components/Sections/RoadmapSection";
import WhyWeFirstSection from "../components/Sections/WhyWeFirstSection";
import MainLayout from "../layouts/MainLayout";

function ServicesPage() {
  return (
    <MainLayout>
      <HomeSection />
      <ContactUsSection />
      <AboutTextContent />
      <WhyWeFirstSection />
      {/* <Calculator /> */}
      <RoadmapSection />
      <GuaranteesSection />
      <BecomeOurClient />
      <PartnersSection />
      <ReviewsSection />
    </MainLayout>
  );
}

export default ServicesPage;
