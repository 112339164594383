import React from "react";

function TableRow(props) {
  const { img, title, priseOpt, priceRozn, weight = " руб/кг", requestPrice, bgGrey, showRozOnly } = props;

  if (!title) return <></>;

  return (
    <tr style={bgGrey ? { background: "#f2f2f2" } : { background: "transparent" }}>
      <th>
        <div>
          <img src={img} alt="Metal img" />
        </div>
      </th>
      <th>{title}</th>

      {showRozOnly && (
        <th>
          {requestPrice && <p>Цена по запросу</p>}
          {!requestPrice && <p>{priceRozn + weight}</p>}
        </th>
      )}

      {showRozOnly || (
        <th>
          {requestPrice && <p>Цена по запросу</p>}
          {!requestPrice && <p>{priseOpt + weight + " оптом"}</p>}
          {!requestPrice && <p>{priceRozn + weight + " в розницу"}</p>}
        </th>
      )}
    </tr>
  );
}

export default TableRow;
