import React, { useContext, useEffect } from "react";
import ContactUsMiniSection from "../components/Sections/ContactUsMiniSection";
import ContactUsSection from "../components/Sections/ContactUsSection";
import ExportSection from "../components/Sections/ExportSection";
import GuaranteesSection from "../components/Sections/GuaranteesSection";
import HomeSection from "../components/Sections/HomeSection";
import PartnersSection from "../components/Sections/PartnersSection";
import ReviewsSection from "../components/Sections/ReviewsSection";
import RoadmapSection from "../components/Sections/RoadmapSection";
import TablePriceSection from "../components/Sections/TablePriceSection";
import WhatWeDoSection from "../components/Sections/WhatWeDoSection";
import WhyWeFirstSection from "../components/Sections/WhyWeFirstSection";
import AppContext from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";

function MainPage() {
  const { setIsSpecialOfferPopup, setIsFixedPopupOpen } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      setIsSpecialOfferPopup(true);
    }, 5000);

    setTimeout(() => {
      setIsFixedPopupOpen(true);
    }, 10000);
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <HomeSection />
      <WhatWeDoSection />
      <ContactUsSection />
      <TablePriceSection />
      <RoadmapSection />
      {/* <SectionWithImage /> */}
      <ContactUsMiniSection />
      <ExportSection />
      <WhyWeFirstSection />
      <GuaranteesSection />
      <PartnersSection />
      <ReviewsSection />
    </MainLayout>
  );
}

export default MainPage;
