import React, { useContext } from 'react'
import styles from '../styles/MetalGroups.module.sass'
import AppContext from "../contexts/AppContext"

function MetalGroups() {
  const appContext = useContext(AppContext)

  // metalGroup 

  const onLinkClick = evt => appContext.setMetalGroup(evt.target.textContent)

  const classNameLi = (name) => {
    const classes = [styles.listItem]

    if (name === appContext.metalGroup) { 
      classes.push(styles.active)
    }

    return classes.join(' ')
  }



  return (
    <div className="container">
      <ul className={styles.list} >
        <li onClick={onLinkClick} className={classNameLi('чёрный металл')}>чёрный металл</li>
        <li onClick={onLinkClick} className={classNameLi('медно-латунная группа')}>медно-латунная группа</li>
        <li onClick={onLinkClick} className={classNameLi('алюминиевая группа')}>алюминиевая группа</li>
        <li onClick={onLinkClick} className={classNameLi('нержавеющая сталь')}>нержавеющая сталь</li>
        <li onClick={onLinkClick} className={classNameLi('свинцово-оловянная группа')}>свинцово-оловянная группа</li>
        <li onClick={onLinkClick} className={classNameLi('лом кабеля')}>лом кабеля</li>
        <li onClick={onLinkClick} className={classNameLi('аккумуляторы')}>аккумуляторы</li>
        <li onClick={onLinkClick} className={classNameLi('прочее')}>прочее</li>
      </ul>
    </div>
  )
}

export default MetalGroups
