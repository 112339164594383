import React from "react";
import PageTitle from "../components/PageTitle";
import HomeSection from "../components/Sections/HomeSection";
import MainLayout from "../layouts/MainLayout";
import License from "../components/License";
import license1 from "../images/licenses/license-1.jpg";
import license2 from "../images/licenses/license-2.jpg";

function Licenses() {
  return (
    <MainLayout>
      <HomeSection />

      <PageTitle title={"Лицензии"} />

      <License
        title="На осуществление Заготовки, хранения, переработки и реализации дома"
        images={[license1, license2]}
      />
    </MainLayout>
  );
}

export default Licenses;
