import React, { useContext, useRef, useState } from "react";
import InputMask from "react-input-mask";
import AppContext from "../../contexts/AppContext";
import styles from "../../styles/sections/ContactUsSection.module.sass";
import BestOfferList from "../BestOfferList";
import ButtonContactUs from "../Buttons/ButtonContactUs";

import emailjs from "@emailjs/browser";
import ErrorToast from "../ErrorToast";

function PhoneInput(props) {
  return (
    <InputMask
      mask="+7 (999) 999-99-99"
      name="phonenum"
      id="phone-mask"
      type="phone"
      placeholder="Номер телефона"
      value={props.value}
      className={styles.input}
      onChange={props.onChange}
    ></InputMask>
  );
}

function ContactUsSection() {
  const { setIsToastOpen } = useContext(AppContext);

  const [msg, setMsg] = useState("");
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);

  const form = useRef();

  function reloadAndClear() {
    setPhone("");
    setUsername("");
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const phonenumel = form.current.phonenum.value;

    if (form.current.username.value.length > 1 && phonenumel.length === 18 && !phonenumel.includes("_")) {
      emailjs.sendForm("service_akwd9qi", "template_c8yo8yg", form.current, "15Ye39pjIHbEXm3Nz").then(
        (result) => {
          reloadAndClear();
          setIsToastOpen(true);

          setTimeout(() => {
            setIsToastOpen(false);
          }, 1500);
        },
        (error) => {
          console.log(error.text);
        }
      );
    } else {
      setMsg("Пожалуйста заполните поля корректно");
      setIsErrorToastOpen(true);
      setTimeout(() => {
        setIsErrorToastOpen(false);
      }, 1500);
    }
  };

  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const handleInput = ({ target: { value } }) => setPhone(value);
  const handleInput2 = ({ target: { value } }) => setUsername(value);

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.bestOfferContainer}>
            <h3> Лучшие условия для сдачи лома !</h3>
            <BestOfferList />
          </div>
          {/* <div className={styles.formContainer}>
            <h3 className={styles.title}>Рассчитаем стоимость за 3 минуты</h3>
            <form onSubmit={sendEmail} ref={form} className={styles.form}>
              <input
                className={styles.input}
                type="text"
                name="username"
                placeholder={"Ваше имя"}
                required
                onChange={handleInput2}
                value={username}
              />

              <PhoneInput value={phone} onChange={handleInput}></PhoneInput>
              <ButtonContactUs />
            </form>
          </div> */}
        </div>
      </div>
      <ErrorToast msg={msg} isErrorToastOpen={isErrorToastOpen} />
    </section>
  );
}

export default ContactUsSection;
