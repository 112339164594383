class Price {
  /** розница */
  roz = 0;
  /** оптовая */
  opt = 0;
  /** цена по запрос */
  requestPrice = false;
  /** ед, измерения */
  weight = " руб/кг";
  /** отображать только roz */
  showRozOnly = false;

  constructor(roz, opt, requestPrice, weight, showRozOnly) {
    roz !== undefined && (this.roz = roz);
    opt !== undefined && (this.opt = opt);
    requestPrice !== undefined && (this.requestPrice = requestPrice);
    weight !== undefined && (this.weight = weight);
    showRozOnly !== undefined && (this.showRozOnly = showRozOnly);
  }
}

class Product {
  name = "";
  category = null;
  price = new Price(0, 0);

  constructor(name, category, price, showRozOnly) {
    name !== undefined && (this.name = name);
    category && (this.category = category);
    price !== undefined && (this.price = price);

    if (showRozOnly !== undefined) {
      this.price.showRozOnly = showRozOnly;
    }
  }
}

export class Products {
  products = [
    // Чёрный металл
    new Product("Чёрный металл", "Чёрный металл", new Price(15, 0), true),
    new Product("Чугун", "Чёрный металл", new Price(15, 0), true),
    // new Product("Лом автомобиля", "Чёрный металл", new Price(20000, 21000, false, " руб/тонна")),

    // Медно-латунная группа
    new Product("Медь а1-1 (блеск)", "Медно-латунная группа", new Price(760, 0), true),
    // new Product("Медь (шина)", "Медно-латунная группа", new Price(730, 0), true),
    new Product("Медь а1-2 (кусок)", "Медно-латунная группа", new Price(740, 0), true),
    new Product("Медь (разносорт.)", "Медно-латунная группа", new Price(740, 0), true),
    new Product("Латунь", "Медно-латунная группа", new Price(450, 0), true),
    new Product("Латунная стружка", "Медно-латунная группа", new Price(0, 0, true), true),
    new Product("Радиаторы медь/латунь", "Медно-латунная группа", new Price(0, 0, true), true),
    new Product("Бронза", "Медно-латунная группа", new Price(0, 0, true), true),
    new Product("Кабель", "Медно-латунная группа", new Price(0, 0, true), true),

    // Алюминиевая группа
    new Product("Алюминий 1-1 (электротехнический)", "Алюминиевая группа", new Price(180, 0), true),
    new Product("Алюминий 1-1 (пищевой)", "Алюминиевая группа", new Price(140, 160)),
    new Product("Алюминий АД-31 (Профиль) чистый", "Алюминиевая группа", new Price(168, 0), true),
    new Product("Алюминий АД-31 (Профиль) грязный", "Алюминиевая группа", new Price(0, 0, true)),
    new Product("Опалубка (засор по факту)", "Алюминиевая группа", new Price(0, 0, true)),
    new Product("Диски", "Алюминиевая группа", new Price(140, 0), true),
    new Product("Алюминиевая банка", "Алюминиевая группа", new Price(60, 80)),
    new Product("Алюминий (разносортный)", "Алюминиевая группа", new Price(110, 120)),
    new Product("Алюминий моторка", "Алюминиевая группа", new Price(110, 120)),
    new Product("Алюминий офсет", "Алюминиевая группа", new Price(0, 0, true)),
    new Product("Алюминий радиаторы", "Алюминиевая группа", new Price(60, 80)),
    new Product("Алюминиевая стружка (засор от 5%)", "Алюминиевая группа", new Price(0, 0, true)),
    new Product("Электродвигатель (корпус алюминий)", "Алюминиевая группа", new Price(0, 0, true)),
    new Product("Электродвигатель (корпус чугун)", "Алюминиевая группа", new Price(0, 0, true)),

    // Нержавеющая сталь
    new Product("Нержавеющая сталь габаритом 0,5х0,5х1,5 м, 10%", "Нержавеющая сталь", new Price(0, 0, true)),
    new Product("Нержавеющая сталь негабаритная 10%", "Нержавеющая сталь", new Price(0, 0, true)),
    new Product("Нержавеющая сталь 8% (в т. ч. негабаритная)", "Нержавеющая сталь", new Price(0, 0, true)),
    new Product("Нержавеющая сталь 6%", "Нержавеющая сталь", new Price(0, 0, true)),

    // Свинцово-оловянная группа
    new Product("Свинец (оболочка кабеля) чистый", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Свинец (переплав) грязный", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Свинец (грузики)", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Цинк (карбюраторный, решётки)", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Цинк (карбюратор в сборе)", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Стальной лом, габариты до 1500х500х500 мм, толщина от 3 мм", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Стальной лом железнодорожный", "Свинцово-оловянная группа", new Price(0, 0, true)),
    new Product("Стальная стружка", "Свинцово-оловянная группа", new Price(0, 0, true)),

    // лом кабеля
    // -

    // Аккумуляторы
    new Product("Аккумуляторы (гель, полипропилен)", "Аккумуляторы", new Price(0, 0, true)),
    new Product("Аккумуляторы (эбонит)", "Аккумуляторы", new Price(0, 0, true)),

    // прочее
    // -
  ];

  getProductsByCategory(categoryName) {
    return this.products.filter((p) => p.category === categoryName);
  }

  getByKey(name) {
    const product = this.products.find((product) => product.name === name);

    return product || new Product();
  }

  getPropsByKey(name) {
    const product = this.products.find((product) => product.name === name) || new Product();

    const props = {
      title: product.name,
      priceRozn: product.price.roz,
      priseOpt: product.price.opt,
      weight: product.price.weight,
      requestPrice: product.price.requestPrice,
      showRozOnly: product.price.showRozOnly,
    };

    return props;
  }
}
