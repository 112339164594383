import React from "react";
import Paragraph from "./Paragraph";
import { motion } from "framer-motion";
import styles from "../styles/License.module.sass";

function License(props) {
  const { title, images } = props;

  return (
    <div className="license">
      {/* <Paragraph text={title} /> */}

      <div className={"container"}>
        <div className={styles.images}>
          {images.map((image) => (
            <motion.img
              initial={{ opacity: 1, x: 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              className={styles.img}
              src={image}
              alt="license"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default License;
