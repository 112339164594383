import React from "react";
import aluET from "../../images/metals/alu-et.jpg";
import TableRow from "../../TableRow";
import { Products } from "../../constants/products";

function BatteryTable() {
  const products = new Products();

  const list = products.getProductsByCategory("Аккумуляторы");

  return (
    <>
      {list.map((p) => (
        <TableRow bgGrey img={aluET} {...products.getPropsByKey(p.name)} />
      ))}
    </>
  );
}

export default BatteryTable;
